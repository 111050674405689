import React from "react";
import FAQSchema from "./FAQSchema";
import sanitizeHtml from "../../utils/htmlSanitizer";
import Accordion, { AccordionItem } from "wonderbly-components/lib/Accordion";
import { useCountry, useModel } from "../../hooks";
import { markdown } from "../Prismic/RichText";

export const useFaqs = (items) => {
  const { topology } = useModel();
  const country = useCountry();

  const schema = new FAQSchema();

  const component = (
    <Accordion preActiveIds={[0]} closeOtherItems>
      {items.map((item, idx) => {
        // Not using the RichText component here as I need to send the rendered
        // html to the schema. Using the component would not return the html
        // but instead a React JSX object. It is because of this I also use
        // dangerouslySetInnerHTML as I didn't want to run the markdown method twice
        const content = sanitizeHtml(
          markdown(item.answer, {
            countryModel: country,
          })
        );

        schema.addQuestion(
          item.question,
          content.replace(/href="\//gm, `href="${topology.base}/`)
        );

        return (
          <AccordionItem key={idx} id={idx} header={<h4>{item.question}</h4>}>
            <div
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          </AccordionItem>
        );
      })}
    </Accordion>
  );

  return {
    schema: schema.data,
    component,
  };
};
