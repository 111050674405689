class FAQSchema {
  constructor() {
    this.data = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [],
    };
  }
  addQuestion(question, answer) {
    this.data.mainEntity.push({
      "@type": "Question",
      name: question,
      acceptedAnswer: {
        "@type": "Answer",
        text: typeof answer !== "string" ? JSON.stringify(answer) : answer,
      },
    });
  }
}

export default FAQSchema;
