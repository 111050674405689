import React from "react";
import PropTypes from "prop-types";
import SectionHeading from "../SectionHeading";
import { useFaqs } from "./helpers";
import "./FAQs.scss";

const FAQs = (props) => {
  const { schema, component } = useFaqs(props.items);

  return (
    <section className="FAQs">
      {props.title && (
        <SectionHeading textAlign="left" tag="h2">
          {props.title}
        </SectionHeading>
      )}
      <div className="container-guttered">
        <div className="FAQs__wrapper">{component}</div>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
        />
      </div>
    </section>
  );
};

FAQs.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.array.isRequired,
    })
  ).isRequired,
};

export default FAQs;
